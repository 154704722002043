@use "sass:color"

@import '../../sass/variables'
@import '../../sass/palette'

.featuredNews
	max-width: 1000px
	@media screen and (min-width: $medium)
		margin: 10rem auto
	h2
		text-align: center
		margin-bottom: 3rem

.newsItems
	display: grid
	gap: 2rem
	grid-template-columns: repeat(2, minmax(0, 1fr))
	padding: 1rem
	@media screen and (max-width: $small)
		grid-template-columns: 1fr
	a
		color: inherit
	p
		display: -webkit-box
		-webkit-box-orient: vertical
		overflow: hidden
		-webkit-line-clamp: 3
		text-indent: 1rem
		line-height: 1.4

.cardLink
	&:hover
		text-decoration: none

.postData
	border: 1px solid $bootstrap-light-gray
	padding: 1rem
	height: 100%
	@media screen and (min-width: $small)
		padding: 1.5rem
	border-radius: .25rem
	transition: box-shadow .7s
	&:hover
		cursor: pointer
		box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 2px 6px -2px rgba(0,0,0,.1)

.dateStamp
	color: $lightGrey
	text-align: right
	font-style: italic
	font-size: .8rem
	margin-bottom: 1rem

.tags
	margin-top: 1rem
	font-size: .7rem
	display: flex
	flex-wrap: wrap
	gap: .5rem
	color: #0066ff

.hashtag, .url
	white-space: nowrap
	overflow: hidden
	text-overflow: ellipsis
	padding: .05rem .2rem
	margin: 0 .25rem
	&:first-child
		margin-left: 0
	border-radius: 4px
	@for $i from 1 through 6 // color them in range
		&:nth-child(#{$i})
			background-color: color.adjust($top-ten-color, $hue: $i * 80)
	&:hover
		background-color: $top-ten-color
		text-decoration: none

.url
	max-width: 85%

@import '../../../sass/variables'
@import '../../../sass/_fonts.sass'

.ofertaSection
	margin: 6rem auto 10rem
	max-width: 800px
	padding: 0 1rem
	h3
		margin-bottom: 3rem
		text-align: center
		font-family: $custom-font
	h4
		margin-top: 2rem
		padding-left: 2rem
	p
		line-height: 1.7
		text-indent: 1rem
		margin-bottom: .75rem
		white-space: pre-line

@import '../../sass/palette'

.pricePosition
	border-bottom: 1px solid $bootstrap-light-gray
	display: flex
	padding: 1rem
	&:last-child
		border-bottom: none

.price
	display: flex
	align-items: center
	align-content: flex-start
	justify-content: flex-start
	justify-items: flex-start
	margin-right: 1rem

.uahIcon
	color: $lightGrey
	height: .9rem

.videoResponsive
	overflow: hidden
	padding-bottom: 56.25%
	position: relative
	height: 0
	margin-bottom: 1.5rem

.videoResponsive iframe
	left: 0
	top: 0
	height: 100%
	width: 100%
	position: absolute

@import '../../sass/variables'

.logoImgContainer
	text-align: center
	margin: 4rem 0

.logoImg
	max-width: 400px
	width: 100%
	padding: 1rem

.mainTextItem
	padding: 3em 0
	@media screen and (min-width: $medium)
		font-size: 1.2rem
		padding: 4rem 0
	p
		text-indent: 1.4em
		line-height: 1.5
		margin-top: 2rem
		max-width: 550px
		margin: auto
	&:last-child
		padding: 0

.animIconContainer,
.ctaContainer
	text-align: center

.animIconContainer
	margin-bottom: 3rem

@import '../../../sass/variables'
@import '../../../sass/fonts'

.footer
	padding: 4rem 0rem
	margin-top: 7rem
	flex-shrink: 0
	background-color: #f5f5f5
	border-top: .05rem solid #dddddd
	box-shadow: 0px -4px 15px -10px rgba(133,133,133,1)

.paymentIconsCont,
.socialIconsCont
	display: flex
	justify-content: center
	justify-items: center

.paymentIconsCont
	margin: 1rem 0
	@media screen and (min-width: $small)
		justify-content: flex-start
	svg
		margin: 0 .33rem

.socialIconsCont
	margin: 2.5rem 0
	@media screen and (min-width: $small)
		justify-content: flex-end
	a
		margin: 0 .66rem

.legalLinks
	margin-top: 1rem
	margin-bottom: 2rem
	text-align: center
	display: flex
	flex-direction: column
	@media screen and (min-width: $small)
		flex-direction: row
		a
			margin-right: 1rem

.contactsContainer
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	text-align: center
	@media screen and (min-width: $small)
		text-align: left
		flex-direction: row
		justify-content: space-between
	strong
		font-weight: 500

.schoolContacts,
.schoolTitle
	margin-bottom: 1rem
	&:first-child
		margin-bottom: 2rem
	p
		font-size: .8rem
		@media screen and (min-width: $medium)
			font-size: 1rem
	p
		&:first-child
			font-size: 1rem
			font-family: $custom-font
			font-style: normal
			margin-bottom: .2rem
			@media screen and (min-width: $medium)
				font-size: 1.3rem

.footerSmallText
	color: $lightGrey
	font-size: .8rem
	text-align: center
	margin: 0
	@media screen and (min-width: $small)
		text-align: left


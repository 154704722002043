@import '../../sass/palette'
@import '../../sass/variables'

.introContainer
	display: flex
	flex-direction: column
	gap: 2rem
	justify-content: center
	@media screen and (min-width: $medium)
		flex-direction: row

.pageIntro
	p
		max-width: 700px
		margin: auto
		text-indent: 2rem
		margin-bottom: .5rem
		@media screen and (min-width: $medium)
			font-size: 1.3rem

.slogan
	font-style: italic

.socialIconsCont
	display: flex
	flex-direction: row
	justify-content: center
	border-bottom: 1px solid $lightGrey
	padding-bottom: 1rem
	@media screen and (min-width: $medium)
		flex-direction: column
		border-right: 1px solid $lightGrey
		border-bottom: none
		padding: 0
	svg
		margin: .33rem
		@media screen and (min-width: $medium)
			margin: 1rem
		min-width: 4rem
		height: 2rem
		color: $lightGrey
		transition: color .5s
		&:hover
			color: $blackText
			cursor: pointer

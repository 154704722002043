@import '../../sass/palette'
@import '../../sass/variables'

.departmentContainer
	margin-bottom: 1.5rem
	border-radius: .25rem

.departmentTitle
	font-size: 1.2rem
	font-weight: 300
	margin: 0 //!
	letter-spacing: 1.7px
	font-weight: 400
	text-align: left
	@media screen and (min-width: $medium)
		font-size: 1.5rem

.listBtn
	z-index: 1
	color: $primary-color !important
	border: 1px solid $light-border-color !important

.previewsCont
	display: flex
	margin: .5rem
	margin-left: auto
	transition: opacity .3s ease-in
	opacity: 1
	&.muted
		opacity: .1
	img
		max-width: 2.5rem
		margin: 0 .3rem
		aspect-ratio: 1 / 1
		object-fit: cover
		object-position: 0 0
		transition: all .2s ease-out

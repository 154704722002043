@import '../../../sass/palette'
@import '../../../sass/variables'

.container
	margin-top: 5rem
	@media screen and (min-width: $medium)
		margin-top: 8rem
	padding: 0 1rem
	h1
		text-align: center
		margin-bottom: 1rem

.spinnerCont
	text-align: center
	margin-top: 5rem

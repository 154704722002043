@import '../../../sass/fonts'
@import '../../../sass/variables'

.mapSection
  margin-top: $defaultMarginTop + 6rem
  h1
    text-align: center
    margin-bottom: 0rem

.mapWrapper
  height: $mapHeight
  margin: auto
  div
    max-height: $mapHeight

.depCardsContainer
  margin-top: 5rem

.ctaContainer
  padding: 0 1rem
  text-align: center
  max-width: 30rem
  margin-top: 5rem
  margin: 7rem auto

.ctaLead
  text-align: center
  margin-bottom: 1rem

.ctaText
  margin-bottom: 2rem

@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@1,500&display=swap')
// @import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@1,400&family=Roboto+Slab:wght@200&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap')

@import 'palette.scss'

$logo-font: 'Roboto Slab', serif // ArtViva
$serif-font: 'EB Garamond', serif // школа мистецтв

$custom-font: 'Open Sans', sans-serif // headings
$sans-serif-font: 'Open Sans', sans-serif // latest sans serif

$headingLtrSpacing: inherit //?

h1, h2, h3, h4
	font-weight: 400
	font-family: $sans-serif-font
	color: $grayish

.custom-font-small
	font-size: 110%

.logo-font
	font-family: $logo-font


@import '../../sass/variables'

.bannerHeading
	text-align: center

.logo
	max-width: 75px
	float: left
	margin-right: 1rem

.message
	margin-top: .5rem

.cta
	text-align: right
	margin-top: 1rem

.bannerLink
	margin-top: .15rem
	display: block
	text-decoration: underline
	&:hover
		color: inherit

@import '../../sass/palette'

.schoolYearInfo
	li
		&::marker
			color: $blackText
		&:nth-child(even)
			list-style: none
			font-style: italic
			margin-bottom: .75rem
			color: $lightGrey

@import '../../sass/variables'
@import '../../sass/fonts'

.carouselContainer
	img
		height: 40vh
		object-fit: cover
		@media screen and (min-width: $medium)
			height: 65vh

.carouselOverlay
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(33, 33, 33, .9))
	height: 8rem
	width: 100%
	position: absolute
	bottom: 0
	@media screen and (min-width: $medium)
		height: 11rem

.caption
	@media screen and (min-width: $medium)
		margin-bottom: 2rem
	p
		font-size: .8rem
		@media screen and (min-width: $medium)
			font-size: 1.2rem

.slideTitle
	color: white
	margin-bottom: .25rem
	@media screen and (min-width: $medium)
		font-size: 3rem

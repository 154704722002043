@import '../../sass/_variables'
@import '../../sass/_palette'

.bannerLayout
	display: flex
	justify-content: center
	align-items: center
	position: fixed
	bottom: 0
	right: 0
	transition: transform .75s ease-in
	z-index: 11
	font-size: .7rem
	padding: 1rem
	padding-bottom: 3rem
	background-color: $primary-color
	color: white
	@media screen and ( min-width: $medium )
		padding: 1.5rem
		padding-left: 3rem
		border-radius: .25rem 0rem 0rem

.hidden
	transform: translateY(+100%)

.visible
	transform: translateY(0)

.bannerContainer
	margin-left: auto
	@media screen and (min-width: $medium)
		font-size: .8rem
		max-width: 750px
	p
		text-indent: 1rem
	button
		border: none
		background-color: transparent
		opacity: .33
		transition: opacity .3s ease-in
		&:hover
			opacity: 1

.closeBtnCont
	display: flex
	justify-content: flex-end

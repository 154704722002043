@import '../../sass/palette'
@import '../../sass/variables'
@import '../../sass/fonts'

.postsContainer
	max-width: 60rem
	margin: 4rem auto

.postLink
	color: $blackText
	&:hover
		text-decoration: none
		color: $blackText

.post
	box-shadow: none
	border: 1px solid #00000018
	padding: 1rem
	margin: 0 auto
	@media screen and (min-width: $medium)
		padding: 1.5rem
	transition: box-shadow .7s
	&:hover
		box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 2px 6px -2px rgba(0,0,0,.1)
		.postImg
			transform: scale(1.02)

.dateStamp
	text-align: right
	font-style: italic
	font-size: .8rem

.imgContainer
	overflow: hidden
	margin-bottom: 1rem

.postImg
	width: 100%
	transition: all .5s ease-out
	object-fit: cover

.message
	text-align: left
	text-indent: 1rem
	overflow: hidden
	white-space: pre-wrap
	font-family: $sans-serif-font
	max-height: 20rem

.postFooter
	display: flex
	justify-content: space-between
	align-items: center
	margin-top: 1rem
	color: $lightGrey

.paginationContainer
	margin-top: 5rem
	text-align: center
	button
		background-color: transparent
		border: 1px solid $primary-color
		border-radius: .4rem
		padding: .5rem 1rem
		text-align: center
		transition: background-color .5s, color .5s, border-color .5s
		min-width: 250px
		min-height: 70px
		font-size: 1.2rem
		&:hover
			background-color: #3b58981c
			border-color: black

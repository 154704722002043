@import '../../../sass/variables'
@import '../../../sass/palette'
@import '../../../sass/fonts'

.section
	margin: auto
	color: $blackText // TODO: need make this more global

.article
	max-width: 50rem
	padding: 0 1rem
	margin: auto
	h1
		text-align: center
		font-family: $custom-font
		margin-bottom: 1rem

.quote
	font-family: $serif-font
	letter-spacing: .075rem
	text-align: right
	margin-bottom: 2rem
	max-width: 280px
	margin-left: auto
	line-height: 1.2rem
	span
		display: block
		margin-top: .5rem
		font-weight: 600

.aboutText
	font-size: .9rem
	letter-spacing: .03rem
	text-indent: 1.4em
	text-align: left
	line-height: 1.4
	margin-bottom: 1rem
	color: $blackText
	text-align: left
	strong
		font-weight: 600
	@media screen and (min-width: $medium)
		font-size: 1.1rem

.wideImgMargins
	margin: 1rem auto 2rem

.inlineImgMarginsLeft,
.inlineImgMarginsRight
	margin: 2rem auto
	@media screen and (min-width: $medium)
		margin-bottom: 1rem

.inlineImgMarginsLeft
	@media screen and (min-width: $small)
		margin-right: 2rem

.inlineImgMarginsRight
	@media screen and (min-width: $small)
		margin-left: 2rem

/* Coolors Exported Palette - coolors.co/d6b68c-dac6ae-e5d4c4-e9ded4-eae5df */

/* RGB */
$color1: #d6b68c;
$color2: #dac6ae;
$color3: #e5d4c4;
$color4: #e9ded4;
$color5: #eae5df;

$blackText: #3f3f3f;
$grayish: #414141;
$lightGrey: #7a7a7a;
$lightestColor: #f8f8f8;
$facebookColor: #3b5998;
$primary-color: #3b5998;
$primary-color-muted: rgb(59, 89, 152, .7);

$month-color: hsl(0, 100%, 93%);
$top-ten-color: hsla(113, 100%, 95%, 0.712);
$warn: hsl(48, 100%, 94%);
$danger: hsl(0, 100%, 95%);

$primary-second: #3b5998;
$primary-second-light: #0051ff0c;

$primary-second-medium: #28a746b0;
$pupil-not-enrolled: rgb(247, 242, 255);
$paid-month: hsla(231, 100%, 50%, 0.185);
$f1-form-text: #6d6d6d;
$logo-sub-text: #303030;
$light-border-color: rgba(0, 0, 0, 0.125);
$google-form-color: rgb(103, 58, 183);
$bootstrap-light-gray: #ced4da;
